import React from 'react';
import SpySerpPanel from '../components/SpySerpApp/SpySerpPanel';
import MetaTags from '../components/Seo/MetaTags';

const AppPage = () => {
  return (
    <>
      <MetaTags title="SpySERP" />
      <SpySerpPanel />
    </>
  );
};

export default AppPage;
