import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import { usePageContext } from './PageContext';
import { getLocalizedPath } from './Link';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { defaultLanguage } from '../../constants';
import { getHrefLang } from '../../utils';

function Helmet(props) {
  const { children, ...restProps } = props;
  const { i18n, getLocalizedPageUrl, currentPage, category, authorId } =
    usePageContext();
  const { language, localized } = i18n;
  const { url } = useSiteMetadata();
  const htmlLang = getHrefLang(language);

  let canonicalPath;
  if (
    ['/terms-of-service/', '/privacy-policy/'].includes(i18n.originalPath) &&
    language !== defaultLanguage
  ) {
    canonicalPath = i18n.originalPath;
  } else if (currentPage && i18n.originalPath.startsWith('/blog/p')) {
    canonicalPath = getLocalizedPath(language, '/blog/');
  } else if (currentPage && category) {
    canonicalPath = getLocalizedPath(language, `/${category}/`);
  } else if (currentPage && authorId) {
    canonicalPath = getLocalizedPath(language, `/author/${authorId}/`);
  } else {
    canonicalPath = getLocalizedPath(language, i18n.originalPath);
  }
  const canonicalUrl = `${url}${canonicalPath}`;

  return (
    <ReactHelmet {...restProps}>
      <html lang={htmlLang} />
      <link rel="canonical" href={canonicalUrl} />

      {localized.length > 0 &&
        localized.map((item) => (
          <link
            rel="alternate"
            key={item.language}
            href={getLocalizedPageUrl(item.language)}
            hrefLang={getHrefLang(item.language)}
          />
        ))}

      {localized.length > 0 && (
        <link
          rel="alternate"
          key={language}
          href={getLocalizedPageUrl(language)}
          hrefLang={getHrefLang(language)}
        />
      )}

      {localized.length > 0 && (
        <link
          rel="alternate"
          href={getLocalizedPageUrl()}
          hrefLang="x-default"
        />
      )}

      {children}
    </ReactHelmet>
  );
}

export default Helmet;
