import React from 'react';
import PropTypes from 'prop-types';
import Helmet from '../../i18n/Helmet';
import { usePageContext } from '../../i18n/PageContext';
import ogpImage from '../../assets/image/spyserp/ogp-image.jpg';

function MetaTags(props) {
  const { title, description, keywords, image, pageTitle, article } = props;
  const { getPageUrl } = usePageContext();
  const url = getPageUrl();

  return (
    <Helmet>
      <title itemprop="headline">{title}</title>
      {description && (
        <meta itemprop="description" name="description" content={description} />
      )}

      {keywords && (
        <meta itemprop="keywords" name="keywords" content={keywords} />
      )}

      <meta property="og:url" content={url} />

      {article && <meta property="og:type" content="article" />}
      {!article && <meta property="og:type" content="website" />}

      {pageTitle && <meta property="og:title" content={pageTitle} />}
      {!pageTitle && <meta property="og:title" content={title} />}

      {description && <meta property="og:description" content={description} />}

      {image && <meta property="og:image" content={image} />}
      {image && <meta name="image" content={image} />}

      {!image && <meta property="og:image" content={ogpImage} />}
      {!image && <meta name="image" content={ogpImage} />}
    </Helmet>
  );
}

export default MetaTags;

MetaTags.propTypes = {
  title: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

MetaTags.defaultProps = {
  title: null,
  pageTitle: null,
  description: null,
  keywords: null,
  image: null,
  article: false,
};
